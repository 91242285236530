import { render, staticRenderFns } from "./KensingtonLocationImportPage.vue?vue&type=template&id=64bc4892&scoped=true"
import script from "./KensingtonLocationImportPage.vue?vue&type=script&lang=js"
export * from "./KensingtonLocationImportPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64bc4892",
  null
  
)

export default component.exports