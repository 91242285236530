<template>
  <CCard class="kensington-locations-import">
    <CCardHeader>
      <slot name="header">
        <b>Kensington Standorte importieren</b>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CCol md="12" v-if="!showTable" class="pl-0 pr-2">
        <vue-csv-import
          v-model="csv"
          :map-fields="mapFields"
        >
          <template slot="hasHeaders" slot-scope="{headers, toggle}">
            <label style="margin-left: -20px;">
              <input type="checkbox" id="hasHeaders" :value="headers" @change="toggle">
              Die Datei hat eine Kopfzeile
            </label>
          </template>

          <template slot="error">
            Der Dateityp ist ungültig
          </template>

          <template slot="thead">
            <CAlert color="warning">
              Der Name des Standortes wird als ID behandelt und darf nicht doppelt vorkommen.<br/>
              Die Postleitzahl-Spalte müssen kommasepariert in der Datei hinterlegt sein. Beispiel: 50969, 50858
            </CAlert>
            <tr>
              <th>Spaltenbeschriftungen den Standorten zuordnen</th>
              <th>Spalten in Ihrer Importdatei</th>
            </tr>
          </template>

          <template slot="next" slot-scope="{load}">
            <CButton color="success" class="mt-5" @click.prevent="load">Datei hochladen  </CButton>
          </template>
        </vue-csv-import>
      </CCol>

      <CCol md="12" v-if="showTable">
        <CDataTable
          :items="csv"
          :fields="getTableFields()"
          hover
          striped
          bordered
          fixed
          column-filter
          table-filter
          items-per-page-select
          sorter
          pagination
          :loading="loading"
        >
          <template #show_details="{item, index}">
            <td class="py-2">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                @click.prevent="showEditModal(index, item)"
              >
                Editieren
              </CButton>
            </td>
          </template>
          <template #delete_item="{item, index}">
            <td class="py-2">
              <CButton
                color="danger"
                variant="outline"
                square
                size="sm"
                @click.prevent="deleteRecord(index)"
              >
                Löschen
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCol>

      <CCol md="12" v-if="visibleActionButton && !showTable" class="text-center">
        <CButton color="primary" @click.prevent="showTable = true">
          Weiter
        </CButton>
      </CCol>

      <CCol md="12" v-if="visibleActionButton && showTable" class="text-center">
        <CButton color="primary" @click.prevent="back()">
          Zurück
        </CButton>
        &nbsp;&nbsp;&nbsp;
        <CButton color="primary" @click.prevent="submit()">
          Import abschließen
        </CButton>
      </CCol>

      <CCol md="12">
        <CForm @submit.prevent="update">
          <CModal
            title="Abonnent hinzufügen"
            size="sm"
            :show.sync="visibleModal"
            :closeOnBackdrop="false"
          >
            <CRow>
              <CCol sm="12">
                <label for="name">Name</label>
                <CInput
                    type="text"
                    id="name"
                    required
                    v-model="selectedItem && selectedItem['Name']"
                />
              </CCol>

              <CCol sm="12">
                <label for="plzRange">PLZ-Bereich</label>
                <CInput
                  id="plzRange"
                  required="true"
                  v-model="selectedItem && selectedItem['PLZ-Bereich']"
                />
              </CCol>

              <CCol sm="12">
                <label for="userEmail">Benutzer E-Mail</label>
                <CInput
                    id="userEmail"
                    required="true"
                    v-model="selectedItem && selectedItem['Benutzer E-Mail']"
                />
              </CCol>

              <CCol sm="12">
                <label for="firstName">Vorname</label>
                <CInput
                    id="firstName"
                    required="true"
                    v-model="selectedItem && selectedItem['Vorname']"
                />
              </CCol>

              <CCol sm="12">
                <label for="lastName">Nachname</label>
                <CInput
                    id="lastName"
                    required="true"
                    v-model="selectedItem && selectedItem['Nachname']"
                />
              </CCol>

              <CCol sm="12">
                <label for="password">Passwort</label>
                <CInput
                    id="password"
                    required="true"
                    v-model="selectedItem && selectedItem['Passwort']"
                />
              </CCol>

              <CCol sm="12">
                <label for="streetAndNr">Straße und Hausnummer</label>
                <CInput
                    id="streetAndNr"
                    required="true"
                    v-model="selectedItem && selectedItem['Straße und Hausnummer']"
                />
              </CCol>

              <CCol sm="12">
                <label for="plz">PLZ</label>
                <CInput
                    id="plz"
                    required="true"
                    v-model="selectedItem && selectedItem['PLZ']"
                />
              </CCol>

              <CCol sm="12">
                <label for="city">Ort</label>
                <CInput
                    id="city"
                    required="true"
                    v-model="selectedItem && selectedItem['Ort']"
                />
              </CCol>

              <CCol sm="12">
                <label for="phone">Telefon</label>
                <CInput
                    id="phone"
                    required="true"
                    v-model="selectedItem && selectedItem['Telefon']"
                />
              </CCol>

              <CCol sm="12">
                <label for="fax">Fax</label>
                <CInput
                    id="fax"
                    required="true"
                    v-model="selectedItem && selectedItem['Fax']"
                />
              </CCol>

              <CCol sm="12">
                <label for="website">Webseite</label>
                <CInput
                    id="website"
                    required="true"
                    v-model="selectedItem && selectedItem['Webseite']"
                />
              </CCol>

              <CCol sm="12">
                <label for="email">E-Mail</label>
                <CInput
                    id="email"
                    required="true"
                    v-model="selectedItem && selectedItem['E-Mail']"
                />
              </CCol>

              <CCol sm="12">
                <label for="contactPerson">Kontaktperson</label>
                <CInput
                    id="contactPerson"
                    required="true"
                    v-model="selectedItem && selectedItem['Kontaktperson']"
                />
              </CCol>

              <CCol sm="12">
                <label for="contactPersonPosition">Kontaktperson Position</label>
                <CInput
                    id="contactPersonPosition"
                    required="true"
                    v-model="selectedItem && selectedItem['Kontaktperson Position']"
                />
              </CCol>

              <CCol sm="12">
                <label for="globalMarkUpOrDown">Auf und Abschlag</label>
                <CInput
                    id="globalMarkUpOrDown"
                    required="true"
                    v-model="selectedItem && selectedItem['Auf und Abschlag']"
                />
              </CCol>

              <CCol sm="12">
                <label for="allowedDomains">Erlaubte Domains</label>
                <CInput
                    id="allowedDomains"
                    required="true"
                    v-model="selectedItem && selectedItem['Erlaubte Domains']"
                />
              </CCol>

            </CRow>

            <div slot="footer">
              <CButton
                class="mr-3"
                color="dark"
                variant="outline"
                @click="visibleModal = false"
              >
                Abbrechen
              </CButton>
              <CButton
                color="success"
                type="submit"
              >
                Speichern
              </CButton>
            </div>
          </CModal>
        </CForm>
      </CCol>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import {VueCsvImport} from 'vue-csv-import'

export default {
  name: 'KensingtonLocationImport',
  components: {
    VueCsvImport,
  },
  mounted() {
    this.customizeFileInput()
  },
  data() {
    return {
      mapFields: [
        'Name',
        'PLZ-Bereich',
        'Benutzer E-Mail',
        'Vorname',
        'Nachname',
        'Passwort',
        'Straße und Hausnummer',
        'PLZ',
        'Ort',
        'Telefon',
        'Fax',
        'Webseite',
        'E-Mail',
        'Kontaktperson',
        'Kontaktperson Position',
        'Erlaubte Domains'
      ],
      csv: null,
      tableData: [],
      showTable: false,
      visibleActionButton: false,
      loading: false,
      visibleModal: false,
      selectedIndex: null,
      selectedItem: null,
    }
  },
  watch: {
    csv(val) {
      if (val) {
        this.visibleActionButton = true
      }
    }
  },
  methods: {
    customizeFileInput() {
      document.querySelectorAll('.kensington-locations-import .csv-import-file')[0].style.display = 'none'

      const divWrapper = document.createElement('div')
      divWrapper.setAttribute('class', 'row')

      const divOffset = document.createElement('div')
      divOffset.setAttribute('class', 'col-sm-3 mb-3')
      divWrapper.appendChild(divOffset)

      const div = document.createElement('div')
      div.setAttribute('class', 'form-group col-sm-9 mb-3')
      const label = document.createElement('label')
      label.setAttribute('for', 'csv-import-file')
      label.setAttribute('class', 'custom-file-label')
      label.style.display = 'block'
      label.innerHTML = 'Datei auswählen...'
      div.appendChild(label)

      divWrapper.appendChild(div)

      const csvInput = document.querySelectorAll('.kensington-locations-import .form-control-file')[0]
      csvInput.addEventListener('change', function (e) {
        label.innerHTML = e.target.files[0].name
      })

      div.addEventListener("click", function () {
        csvInput.click()
      })
      const partOne = document.querySelectorAll('.kensington-locations-import .vue-csv-uploader-part-one')[0]
      partOne.insertBefore(divWrapper, partOne.children[2])
    },
    getTableFields() {
      return [
        {
          key: 'Name',
          label: 'Name',
        },
        {
          key: 'PLZ-Bereich',
          label: 'PLZ-Bereich',
        },
        {
          key: 'Benutzer E-Mail',
          label: 'Benutzer E-Mail',
        },
        {
          key: 'Vorname',
          label: 'Vorname',
        },
        {
          key: 'Nachname',
          label: 'Nachname',
        },
        {
          key: 'Passwort',
          label: 'Passwort',
        },
        {
          key: 'Straße und Hausnummer',
          label: 'Straße und Hausnummer',
        },
        {
          key: 'PLZ',
          label: 'PLZ',
        },
        {
          key: 'Ort',
          label: 'Ort',
        },
        {
          key: 'Telefon',
          label: 'Telefon',
        },
        {
          key: 'Fax',
          label: 'Fax',
        },
        {
          key: 'Webseite',
          label: 'Webseite',
        },
        {
          key: 'E-Mail',
          label: 'E-Mail',
        },
        {
          key: 'Kontaktperson',
          label: 'Kontaktperson',
        },
        {
          key: 'Kontaktperson Position',
          label: 'Kontaktperson Position',
        },
        {
          key: 'Erlaubte Domains',
          label: 'Erlaubte Domains',
        },
        {
          key: 'show_details',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false
        },
        {
          key: 'delete_item',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false
        }
      ]
    },
    back() {
      this.showTable = false
      setTimeout(() => {
        this.customizeFileInput()
      }, 100)
    },
    async submit() {
      this.loading = true
      const locations = this.csv
      if (locations && locations.length > 0) {
        try {
          this.loading = true
          const formattedLocations = this.formatLocationsData(locations)
          await this.addLocations(formattedLocations)
        } catch (err) {
          console.log(err)
          alert('Fehler beim importieren der Abonennten')
        } finally {
          this.loading = false
        }
      } else if (!this.csv || (this.csv && this.csv.length === 0)) {
        alert('Nichts hinzuzufügen')
      } else {
        alert('Bereits importierte Kontakte')
      }
    },
    formatPostalCodeList(postalCodeString) {
      if (postalCodeString) {
        return _.uniq(postalCodeString.split(',').map(s => s.trim()))
      }
      return []
    },
    formatLocationsData(locations) {
      let formattedLocations = []
      locations && locations.forEach(subscriber => {
        const entity = {
          name: subscriber['Name'],
          firstName: subscriber['Vorname'],
          lastName: subscriber['Nachname'],
          email: subscriber['Benutzer E-Mail'],
          postalCodeList: this.formatPostalCodeList(subscriber['PLZ-Bereich']),
          companySettings: {
            streetAndNumber: subscriber['Straße und Hausnummer'],
            postalCode: subscriber['PLZ'],
            city: subscriber['Ort'],
            phoneNumber: subscriber['Telefon'],
            fax: subscriber['Fax'] || '',
            website: subscriber['Webseite'],
            email: subscriber['E-Mail'],
            contactPerson: subscriber['Kontaktperson'],
            contactPersonPosition: subscriber['Kontaktperson Position'],
            globalMarkUpOrDown: subscriber['Auf und Abschlag'],
          },
          initialPassword: subscriber['Passwort'],
          allowedDomains: subscriber['Erlaubte Domains']
        }
        formattedLocations.push(entity)
      })

      return formattedLocations
    },
    async addLocations(locations) {
      try {
        this.loading = true
        await axios({ method: 'POST', url: '/kensington/location/multiple', data: locations, baseURL: process.env.VUE_APP_PLUGIN_BASE_URL })
        this.$toaster.makeToast("Erfolgreich!", "Erfolgreich importiert")
        await this.$router.push({name: 'KensingtonLocations'})
      } catch (e) {
        console.log(e)
        this.$toaster.makeToast("Fehler!", "Fehler beim Import. Versuchen Sie es zu einem späteren Zeitpunkt wieder. " + e.response ? e.response.data : '')
      } finally {
        this.loading = false
      }
    },
    showEditModal(index, item) {
      this.selectedIndex = index
      this.selectedItem = item
      this.visibleModal = true
    },
    update() {
      if (this.selectedIndex !== null) {
        this.csv && this.csv.splice(this.selectedIndex, 1, this.selectedItem)
        this.visibleModal = false
      }
    },
    deleteRecord(index) {
      this.csv && this.csv.splice(index, 1)
    }
  }
}
</script>

<style>
  .cursor-pointer {
    cursor: pointer;
  }
</style>
